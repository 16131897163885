const Letter = (props) => {

    let disabled = false;
    for (let i = 0; i < props.guessed.length; i++) {
        if (props.guessed[i] === props.letter) {
            disabled = true;
            break;
        }
    }

    let latest = false;
    if (props.guessed[props.guessed.length - 1] === props.letter) {
        latest = true;
    }

    let correct = false;
    if (props.word.includes(props.letter)) {
        correct = true;
    }

    let classes = 'Letter';
    if (disabled) {
        classes += ' disabled';
    }
    if (latest) {
        classes += ' animate animate__flip'
    }
    if (correct) {
        classes += ' green'
    } else {
        classes += ' red'
    }

    return (
        <div onClick={(e) => {props.handleClick(e)}} className={classes} data-letter={props.letter}>
           { props.letter}
        </div>
    )
}

export default Letter;