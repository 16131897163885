const Home = (props) => {
    return (
        <div className="home-screen">
            <h1 className="title">
                <span className="title-letter">H</span>
                <span className="title-letter">&nbsp;</span>
                <span className="title-letter">N</span>
                <span className="title-letter">G</span>
                <span className="title-letter">L</span>
                <span className="title-letter">E</span>
            </h1>
            <button className="mode-btn btn--daily" onClick={() => props.switchScreen('daily')}>Daily Challenge</button>
            <button className="mode-btn btn--practice" onClick={() => props.switchScreen('practice')}>Practice Mode</button>
            <button className="mode-btn btn--instructions" onClick={() => props.openModal('instructions')}>{props.information}</button>
        </div>
    )
}

export default Home;