const translations = {
    "en": {
        "information": {
            "info-btn": "Information",
            "instructions-title": "Instructions",
            "instructions-content-1": "Solve the mystery word by guessing one letter at a time.",
            "instructions-content-2": "If you make an incorrect guess, you will lose a chance.",
            "instructions-content-3": "If you guess a letter correctly, you will maintain your remaining chances.",
            "instructions-content-4": "Try to solve the word before running out of chances.",
            "word-info-1": "Words are taken from a list of common English words, often found in ESL tests such as TOEIC, TOEFL, IELTS or GRE.",
            "word-info-2": "The word list contains almost 10,000 words.",
            "practice-title": "Practice Mode",
            "practice-content-1": "Unlimited play with the full word database.",
            "practice-content-2": "Toggle difficulty (number of chances).",
            "hint-title": "Need a hint? (Practice Mode Only)",
            "hint-content-1": "Click the \"Need a hint\" button to view a definition for the word.",
            "hint-content-2": "Seeing the definition costs one chance.",
            "hint-content-3": "Definitions are taken from an online dictionary. They might be very helpful or not helpful at all.",
            "hint-content-4": "It is possible a definition cannot be found. In this case, you won't lose a chance.",
        }
    },
    "kr": {
        "information": {
            "info-btn": "게임 방법",
            "instructions-title": "게임 방법",
            "instructions-content-1": "수수께끼의 단어를 한 번에 한 글자씩 추측해서 맞춰보세요.",
            "instructions-content-2": "추측이 틀리면 기회가 1개씩 줄어들어요.",
            "instructions-content-3": "글자를 맞추면 기회가 줄어들지 않아요.",
            "instructions-content-4": "기회가 다 없어지기 전에 단어를 맞춰보세요.",
            "word-info-1": "단어들의 출처는 TOEIC, TOEFL, IELTS, GRE 등의 영어 시험에 자주 등장하는 단어 리스트에요.",
            "word-info-2": "이 단어리스트에는 10,000개 정도의 단어가 포함되어 있어요",
            "practice-title": "연습 모드",
            "practice-content-1": "무제한으로 연습할 수 있어요.",
            "practice-content-2": "Toggle difficulty 버튼을 누르면 주어지는 기회 수를 변경할 수 있어요.",
            "hint-title": "힌트가 필요한가요? (연습 모드만 해당)",
            "hint-content-1": "Need a hint 버튼을 누르면 단어의 뜻이 나옵니다.",
            "hint-content-2": "단어의 뜻을 보면 기회가 1개 줄어들어요.",
            "hint-content-3": "온라인 사전에서 찾은 단어의 뜻이 표시됩니다.",
            "hint-content-4": "도움이 될 수도 있고, 전혀 도움이 안 될 수도 있어요. 만약 버튼을 눌렀는데 단어의 뜻이 안나오는 경우에는 기회가 줄어들지 않아요.",
        }
    },
    "jp": {
        "information": {
            "info-btn": "ルール",
            "instructions-title": "ルール",
            "instructions-content-1": "1文字ずつ予想しミステリーワードを完成させてください。",
            "instructions-content-2": "不正解の場合はチャンスを一つ失います。",
            "instructions-content-3": "正解の場合は残りのチャンスは減りません。",
            "instructions-content-4": "チャンスが全てなくなる前に単語を完成させてください。",
            "word-info-1": "単語はTOEIC, TOEFL, IRLTS, GREなどの英語の試験によく出題される単語リストから抜粋しました。",
            "word-info-2": "リストには約10,000語が含まれています。",
            "practice-title": "Practice Mode 練習モード",
            "practice-content-1": "無制限プレイできます。",
            "practice-content-2": "Toggle difficulty ボタンを押したらチャンスの数が変更できます。",
            "hint-title": "ヒントが必要な場合は？（練習モードのみ）",
            "hint-content-1": "Need a hintボタンを押したら単語の意味が表示されます。",
            "hint-content-2": "単語の意味を見るとチャンス1つが減ります。",
            "hint-content-3": "単語の意味はオンライン辞書から引用しています。役に立つ場合も、立たない場合もあります。",
            "hint-content-4": "もしボタンを押しても意味が表示されない場合はチャンスは減りません。",
        }
    }
}

export default translations;