const Character = (props) => {
    return (
        <div className="Character">
            <span className={ props.show ? 'animate animate__fadeCharInUp' : 'animate'}>
                { props.show ? props.letter : '' }
            </span>
        </div>
    )
}

export default Character;