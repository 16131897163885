import React from 'react';

import Letter from './Letter';

const Letters = (props) => {
    return (
        <div>
        <div className='Letters Letters--large'>
            <div className="Letters__row">
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'q'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'w'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'e'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'r'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'t'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'y'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'u'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'i'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'o'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'p'} />
            </div>
            <div className='Letters__row'>
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'a'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'s'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'d'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'f'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'g'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'h'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'j'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'k'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'l'} />
            </div>
            <div className='Letters__row'>
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'z'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'x'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'c'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'v'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'b'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'n'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'m'} />
            </div>
            <p className='keyboard-instructions'>
                Use keyboard or click the letters
            </p>
        </div>

        <div className='Letters Letters--small'>
            <div className="Letters__row">
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'a'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'b'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'c'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'d'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'e'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'f'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'g'} />
            </div>
            <div className='Letters__row'>
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'h'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'i'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'j'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'k'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'l'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'m'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'n'} />
            </div>
            <div className='Letters__row'>
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'o'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'p'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'q'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'r'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'s'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'t'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'u'} />
            </div>
            <div className='Letters__row'>
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'v'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'w'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'x'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'y'} />
                <Letter word={props.word} guessed={props.guessed} handleClick={props.handleClick} letter={'z'} />
            </div>
        </div>
        </div>
    )
}

export default Letters;