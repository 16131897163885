import React from 'react';

const DailyGraphic = (props) => {
    let naviShare = navigator.share;

    // props.getDefinition(props.word);

    return (
        <div className='Graphic hidden'>
            <span id='result'></span>
            <span id='answer'></span>

            <div className="definition-wrapper--graphic"></div>
            
            <button className='share-btn' onClick={() => {props.share()}}>Copy Result</button>

           { naviShare ? <button className='share-btn--native' onClick={() => {props.nativeShare()}}>Share</button> : ''} 
            
            <div className='new-word-timer'>
                <p>New word in:</p>
                <p data-time-remaining>---</p>
            </div>

            <div className='ps-ref'>
                <a className='ps-ref__link' href="https://purple-stew.com?ref=WordHangle" target="_blank" rel="noreferrer">Love word games?</a>
            </div>
            
        </div>
    )
}

export default DailyGraphic;