import React from 'react';

const Graphic = (props) => {
    return (
        <div className='Graphic hidden'>
            <span id='result'></span>
            <span id='answer'></span>

            <div className="definition-wrapper--graphic"></div>
            
            <button className='reset-btn' onClick={() => {props.resetBoard()}}>Replay?</button>
            
        </div>
    )
}

export default Graphic;