import React from 'react';

import Character from './Character';

const Word = (props) => {
    const word_arr = props.word.split('');

    return (
        <div className='Word'>
            {
                word_arr.map((elem, index) => {
                    let show = false;
                    for (let i = 0; i < props.guessed.length; i++) {
                        if (props.guessed[i] === elem) {
                            show = true;
                            break;
                        }
                    }
                    return <Character letter={elem} key={index} index={index} guessed={props.guessed} show={show} />
                })
            }

        </div>
    )
}

export default Word;