import DailyGraphic from './components/DailyGraphic';
import Word from './components/Word';
import Letters from './components/Letters';
import React from 'react';

const Daily = (props) => {

    let remainingChances = props.state.chances - props.state.incorrectGuesses;
    let chanceHTML = "";
    for (let i = 0; i < remainingChances; i++) {
        chanceHTML += "💚";
    }
    for (let i = 0; i < props.state.incorrectGuesses; i++) {
        chanceHTML += "💔";
    }
    
    return (
        <div>
            <div className='navigation'>
                <button className='home-btn' onClick={props.returnHome}>Home</button>
            </div>
            <div>
                <h3 className='mode-title'>
                    Daily Challenge
                </h3>
                <p className='chances-remaining'>
                    { chanceHTML }
                    <br />
                    <span className='text--subdued'>({ `${remainingChances} / ${ props.state.chances }` })</span>
                </p>
            </div>
            
            <DailyGraphic share={props.share} nativeShare={props.nativeShare}/>
            <Word word={props.state.word} guessed={props.state.guessed}/>
            <Letters word={props.state.word} guessed={props.state.guessed} handleClick={props.handleClick}/>
        </div>
    )
    
}

export default Daily;