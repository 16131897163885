import Graphic from './components/Graphic';
import Word from './components/Word';
import Letters from './components/Letters';

const Practice = (props) => {

    let remainingChances = props.state.chances - props.state.incorrectGuesses;
    let chanceHTML = "";
    for (let i = 0; i < remainingChances; i++) {
        chanceHTML += "💚";
    }
    for (let i = 0; i < props.state.incorrectGuesses; i++) {
        chanceHTML += "💔";
    }

    return (
        <div>
            <div className='navigation'>
                <button className='home-btn' onClick={props.returnHome}>Home</button>
            </div>
            <div>
                <h3 className='mode-title'>
                    Practice Mode
                </h3>
                <p className='current-difficulty'>
                    { `Difficulty: ${(props.state.difficulty).toUpperCase()}`}
                </p>

                <button className='toggle-btn' onClick={() => {props.toggleDifficulty()}}>Toggle Difficulty</button>

                <p className='chances-remaining'>
                    { chanceHTML }
                    <br />
                    <span className='text--subdued'>({ `${remainingChances} / ${ props.state.chances }` })</span>
                </p>
            </div>
            
            <Graphic resetBoard={props.resetBoard}/>
            <Word word={props.state.word} guessed={props.state.guessed}/>
            <Letters word={props.state.word} guessed={props.state.guessed} handleClick={props.handleClick}/>

            <div className='definition'>
                <button className='btn--definition' onClick={() => {props.getDefinition(props.state.word)}}>Need a hint?</button>
                <div className='definition-wrapper'></div>
            </div> 
            
        </div>
    )
}

export default Practice;