import React from 'react';
import './App.css';

import Home from './Home';
import Daily from './Daily';
import Practice from './Practice';

import data from './data/words';
import translations from './translations/translations';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      "difficulty": 'medium',
      "chances": 6,
      "incorrectGuesses": 0,
      "word": this.generateWord(data),
      "guessed": [],
      "gameOver": false,
      "win": false,
      "screen": 'home',
      "language": 'en'
    };

    this.switchScreen = this.switchScreen.bind(this);
    this.guessLetter = this.guessLetter.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.checkIncorrectGuess = this.checkIncorrectGuess.bind(this);
    this.checkAllGuessed = this.checkAllGuessed.bind(this);
    this.toggleDifficulty = this.toggleDifficulty.bind(this);
    this.generateWord = this.generateWord.bind(this);
    this.updateGraphic = this.updateGraphic.bind(this);
    this.calcTimeRemaining = this.calcTimeRemaining.bind(this);
    this.resetBoard = this.resetBoard.bind(this);
    this.share = this.share.bind(this);
    this.nativeShare = this.nativeShare.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.appendGraphicText = this.appendGraphicText.bind(this);
    this.returnHome = this.returnHome.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.checkCookie = this.checkCookie.bind(this);
    this.intToDay = this.intToDay.bind(this);
    this.intToMonth = this.intToMonth.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getDefinition = this.getDefinition.bind(this);
    this.getGraphicDefinition = this.getGraphicDefinition.bind(this);
    this.clearDefinition = this.clearDefinition.bind(this);
    this.clearGraphicDefinition = this.clearGraphicDefinition.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);

    this.updateTimer = false;
  }

   // ========= //
  // FUNCTIONS //
 // ========= //

  generateWord(words) {
    let num = Math.floor(Math.random() * (words.length - 1));
    let word = words[num].toLowerCase();

    return word;
  }

  generateDailyWord(words) {
    let seed = new Date()
    let string = `${seed.getDate()}-${seed.getMonth()+1}-${seed.getFullYear()}`

    let prng = new Math.seedrandom(string)
    
    let num = Math.floor(prng.quick() * (words.length - 1));
    let word = words[num].toLowerCase();

    return word;
  }

  switchScreen(screen) {
    switch(screen) {
      case "practice":
        this.setState({
          screen
        })
        break;
      case "daily":
        this.checkCookie();
        break;
      default:
        this.resetBoard();
        this.setState({
          "screen": "home"
        })
        break;
    }
  }

  returnHome() {
    clearInterval(this.updateTimer)
    this.switchScreen(false)
  }

  toggleDifficulty() {
    let newDifficulty = "medium";
    let newChances = 6;

    switch (this.state.difficulty) {
      case "easy":
        newDifficulty = "medium";
        newChances = 6;
        break;
      case "medium":
        newDifficulty = "hard";
        newChances = 5;
        break;
      case "hard":
        newDifficulty = "legendary";
        newChances = 4;
        break;
      case "legendary":
        newDifficulty = "easy";
        newChances = 7;
        break;
      default:
        newDifficulty = "medium";
        newChances = 6;
        break;
    }

    // Enable definition btn
    let definitionBtn = document.querySelector('.btn--definition');
    if (definitionBtn) {
      definitionBtn.removeAttribute('disabled');
      this.clearDefinition();
    }
    this.clearGraphicDefinition();

    // Update state
    this.setState({
      difficulty: newDifficulty,
      chances: newChances,
      "incorrectGuesses": 0,
      "word": this.generateWord(data),
      "guessed": [],
      "gameOver": false,
      "win": false
    })
  }

  checkIncorrectGuess(char) {
    let result = false;
    if (!this.state.word.includes(char)) {
     result = true;
    }
    return result;
  }

  checkAllGuessed(guessed) {
    let result = true;
    this.state.word.split('').forEach((elem, index) => {
      if (!guessed.includes(elem)) {
        result = false;
      }
    })
    return result;
  }

  guessLetter(char) {
    if (this.state.gameOver) {
      return;
    }

    if (this.state.guessed.includes(char)) {
      return;
    };

    let newGuessed = this.state.guessed;
    newGuessed.push(char);

    // Check for all letters guessed
    let allGuessed = this.checkAllGuessed(newGuessed);

    // Check for incorrect guess
    let incorrectGuess = this.checkIncorrectGuess(char);

    let newIncorrectGuesses = this.state.incorrectGuesses;
    if (incorrectGuess) {
      newIncorrectGuesses += 1;
    }

    // Update state
    this.setState({
      guessed: newGuessed,
      incorrectGuesses: newIncorrectGuesses,
      gameOver: newIncorrectGuesses === this.state.chances ? true : allGuessed,
      win: newIncorrectGuesses === this.state.chances ? false : allGuessed
    })
  }

  handleKeyDown = (event) => {
    if (event.keyCode < 65 || event.keyCode > 90) {
      return;
    }

    this.guessLetter(event.key);
  }

  handleClick(e) {
    e.preventDefault();

    let letter = e.target.getAttribute('data-letter');

    this.guessLetter(letter)
  }

  openModal(type) {
    let modal = document.querySelector('.Modal');
    modal.classList.add('open');

    if (type === 'instructions') {
      let container = modal.querySelector('.modal__inner');

      let temp = "";
      temp += `
      <div class="game-information">
        <div class="u-mb--small">
          <p class="u-mb--small"><strong>${translations[this.state.language].information['instructions-title']}</strong></p>
          <p>${translations[this.state.language].information['instructions-content-1']}</p>
          <p>${translations[this.state.language].information['instructions-content-2']}</p>
          <p>${translations[this.state.language].information['instructions-content-3']}</p>
          <p>${translations[this.state.language].information['instructions-content-4']}</p>
        </div>
        <div class="u-mb--medium">
          ${translations[this.state.language].information['word-info-1']}
          ${translations[this.state.language].information['word-info-2']}
        </div>
        <div class="u-mb--medium">
          <p class="u-mb--small"><strong>${translations[this.state.language].information['practice-title']}</strong></p>
          <p>${translations[this.state.language].information['practice-content-1']}</p>
          <p>${translations[this.state.language].information['practice-content-2']}</p>
        </div>
        <div>
          <p class="u-mb--small"><strong>${translations[this.state.language].information['hint-title']}</strong></p>
          <p>${translations[this.state.language].information['hint-content-1']}</p>
          <p>${translations[this.state.language].information['hint-content-2']}</p>
          <p>${translations[this.state.language].information['hint-content-3']}</p>
          <p>${translations[this.state.language].information['hint-content-4']}</p>
        </div>
      </div>
      `;

      container.innerHTML = temp;

      modal.appendChild(container)
    }
  }

  closeModal() {
    let modal = document.querySelector('.Modal');
    let innerModal = modal.querySelector('.modal__inner');
    innerModal.innerHTML = '';
    modal.classList.remove('open');
  }

  showNotification(success, text) {
    let graphic = document.querySelector('.Graphic');

    let currentNote = graphic.querySelector('.notification');
    if (currentNote) {
      return;
    }

    let note  = document.createElement('p');
    note.classList.add('notification')

    if (!success) {
      note.classList.add('error')
    }

    note.innerHTML = text;

    graphic.appendChild(note);

    if (success) {
      setTimeout(() => {
        graphic.removeChild(note);
      }, 3500);
    }
  }

  appendGraphicText(title, textResult, emojiResult) {
    let graphic = document.querySelector('.Graphic');

    let copyBox = graphic.querySelector('.manual-copy-text');
    if (copyBox) {
      return;
    }

    let div = document.createElement('div');
    div.classList.add('manual-copy-text')


    div.innerHTML = `
    <p>${title}</p>
    <p>${textResult}</p>
    <p>${emojiResult}</p>
    `;

    graphic.appendChild(div);
  }

  share() {
    let today = new Date().toISOString().slice(0, 10);

    let title = `Hangle ${today}`

    let link = 'https://wordhangle.com';

    let remainingChances = this.state.chances - this.state.incorrectGuesses;
    let chanceHTML = "";
    for (let i = 0; i < remainingChances; i++) {
        chanceHTML += "💚";
    }
    for (let i = 0; i < this.state.incorrectGuesses; i++) {
        chanceHTML += "💔";
    }

    let textResult = chanceHTML;

    let emojiResult = '';
    let wordChars = this.state.word.split('');
    for (let i = 0; i < wordChars.length; i++) {
      if (this.state.guessed.includes(wordChars[i])) {
        emojiResult += '🟩';
      } else {
        emojiResult += '❌';
      }
    }

    let copiedResult = title + '\n' + textResult + '\n' + emojiResult + '\n' + link + '\n';

    navigator.clipboard.writeText(copiedResult).then(() => {
      /* clipboard successfully set */
      this.showNotification(true, 'Successfully coppied to clipboard!')
    }, () => {
      /* clipboard write failed */
      this.showNotification(false, 'Copy doesn\'t work in this browser... Try copying manually!')
      this.appendGraphicText(title, textResult, emojiResult);
    });
  }

  nativeShare() {
    let today = new Date().toISOString().slice(0, 10);
    let title = `Hangle ${today}`
    let link = 'https://wordhangle.com';

    let remainingChances = this.state.chances - this.state.incorrectGuesses;
    let chanceHTML = "";
    for (let i = 0; i < remainingChances; i++) {
        chanceHTML += "💚";
    }
    for (let i = 0; i < this.state.incorrectGuesses; i++) {
        chanceHTML += "💔";
    }

    let textResult = chanceHTML;

    let emojiResult = '';
    let wordChars = this.state.word.split('');
    for (let i = 0; i < wordChars.length; i++) {
      if (this.state.guessed.includes(wordChars[i])) {
        emojiResult += '🟩';
      } else {
        emojiResult += '❌';
      }
    }

    let text = title + '\n' + textResult + '\n' + emojiResult

    navigator.share({
      url: link,
      title: title,
      text: text
    });
  }

  resetBoard() {
    let chances = '6';
    switch (this.state.difficulty) {
      case "easy":
        chances = 7;
        break;
      case "medium":
        chances = 6;
        break;
      case "hard":
        chances = 5;
        break;
      case "legendary":
        chances = 4;
        break;
      default:
        chances = 6;
    }

    const graphic = document.querySelector('.Graphic')
    graphic.classList.add('hidden');
    graphic.classList.remove('animate', 'animate__jackInTheBox');

    let newWord = this.generateWord(data);

    // Enable definition btn
    let definitionBtn = document.querySelector('.btn--definition');
    if (definitionBtn) {
      definitionBtn.removeAttribute('disabled');
      this.clearDefinition();
    }
    this.clearGraphicDefinition();

    this.setState({
      "chances": chances,
      "incorrectGuesses": 0,
      "word": newWord,
      "guessed": [],
      "gameOver": false,
      "win": false
    });
  }

  updateGraphic(win) {
    const graphic = document.querySelector('.Graphic')
    const result = document.querySelector('#result')
    const answer = document.querySelector('#answer')
    const timeRemainingElem = document.querySelector('[data-time-remaining]')

    if (win) {
      result.innerHTML = "Winner!"
    } else {
      result.innerHTML = "You Lose!"
    }

    graphic.classList.remove('hidden');
    graphic.classList.add('animate', 'animate__jackInTheBox');
    answer.innerHTML = this.state.word;

    if (timeRemainingElem) {
      this.updateTimer = setInterval(() => {
        timeRemainingElem.innerHTML = this.calcTimeRemaining();
      }, 1000);
    }

    this.getGraphicDefinition(this.state.word, true)
 
    if (win) {
      document.dispatchEvent(new CustomEvent('releaseConfetti'));
    }
  }

  calcTimeRemaining() {
    let today = new Date();

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0,0,0,0);

    let diffMs = (tomorrow - today);

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }
    
    function convertMsToTime(milliseconds) {
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
  
      seconds = seconds % 60;
      minutes = minutes % 60;
  
      hours = hours % 24; // convert to 12 hour time
  
      return `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
    }

    let timeRemaining = convertMsToTime(diffMs);

    return timeRemaining;
  }

  intToDay(int) {
    let result;
    switch(int) {
      case 0:
        result = 'Mon';
        break;
      case 1:
        result = 'Tue';
        break;
      case 2:
        result = 'Wed';
        break;
      case 3:
        result = 'Thu';
        break;
      case 4:
        result = 'Fri';
        break;
      case 5:
        result = 'Sat';
        break;
      case 6:
        result = 'Sun';
        break;
      default:
        result = 'Mon';
        break;
    }
    return result;
  }

  intToMonth(int) {
    let result;
    switch(int) {
      case 0:
        result = 'Jan';
        break;
      case 1:
        result = 'Feb';
        break;
      case 2:
        result = 'Mar';
        break;
      case 3:
        result = 'Apr';
        break;
      case 4:
        result = 'May';
        break;
      case 5:
        result = 'Jun';
        break;
      case 6:
        result = 'Jul';
        break;
      case 7:
        result = 'Aug';
        break;
      case 8:
        result = 'Sep';
        break;
      case 9:
        result = 'Oct';
        break;
      case 10:
        result = 'Nov';
        break;
      case 11:
        result = 'Dec';
        break;
      default:
        result = 'Jan';
        break;
    }
    return result;
  }

  setCookie() {
    let cname, cvalue, exdays;
    if (!cname) {
      let today = new Date()
      let today_date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      cname = `daily-hangle-${today_date}`;
    }
    if (!cvalue) {
      let saveState = {
        "chances": this.state.chances,
        "incorrectGuesses": this.state.incorrectGuesses,
        "word": this.state.word,
        "guessed": this.state.guessed,
        "gameOver": this.state.gameOver,
        "win": this.state.win,
      };
      saveState = JSON.stringify(saveState);

      cvalue = saveState
    }

    if (!exdays) {
      exdays = 2;
    }

    const d = new Date();
    // d.setTime(d.getTime() + (exdays*24*60*60*1000));
    // let expires = "expires="+ d.toUTCString();
    let expires = `expires=${this.intToDay(d.getDay())}, ${d.getDate() +1} ${this.intToMonth(d.getMonth())} ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} GMT`;

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie() {
    let cname;
    if (!cname) {
      let today = new Date()
      let today_date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      cname = `daily-hangle-${today_date}`;
    }

    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async checkCookie() {
    let lastState = this.getCookie();

    if (lastState !== "") {
      lastState = await JSON.parse(lastState);

      this.setState({
        "chances": lastState.chances,
        "incorrectGuesses": lastState.incorrectGuesses,
        "word": lastState.word,
        "guessed": lastState.guessed,
        "gameOver": lastState.gameOver,
        "win": lastState.win,
        "screen": "daily",
      })
    } else {
      this.setState({
        "chances": 6,
        "incorrectGuesses": 0,
        "word": this.generateDailyWord(data),
        "guessed": [],
        "gameOver": false,
        "win": false,
        "screen": "daily",
      })
    }
  }

  clearDefinition() {
    let definitionWrapper = document.querySelector('.definition-wrapper');
    if (!definitionWrapper) return;

    definitionWrapper.innerHTML = '';
  }

  clearGraphicDefinition() {
    let definitionWrapper = document.querySelector('.definition-wrapper--graphic');
    if (!definitionWrapper) return;

    definitionWrapper.innerHTML = '';
  }

  async getDefinition(word) {
    // Disable btn
    let definitionBtn = document.querySelector('.btn--definition');
    definitionBtn.setAttribute('disabled', true);

    // Check for definition wrapper
    let definitionWrapper = document.querySelector('.definition-wrapper');

    if (!definitionWrapper) return;
    if (definitionWrapper.innerHTML !== '') return;

    // Get definition from dictionary API
    let headersList = {
      "Accept": "*/*"
    }
    
    let response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`, { 
      method: "GET",
      headers: headersList
    });
    
    let data = await response.json();

    // Check response
    if (data.title) {
      // TODO: Something went wrong with the request
      definitionWrapper.innerHTML = "<p>Sorry, we couldn't find a definition at this time</p>";
      return;
    };

    // Output definition
    this.clearDefinition();

    let partOfSpeech = data[0]?.meanings[0]?.partOfSpeech;
    // let firstDefinition = data[0]?.meanings[0]?.definitions?.[0]?.definition;
    // firstDefinition = firstDefinition.replaceAll(this.state.word, '???');

    let html = '';
    html += `<p>${partOfSpeech}</p>`;
    // html += `<p>${firstDefinition}</p>`;

    definitionWrapper.innerHTML = html;

    let newIncorrectGuesses = this.state.incorrectGuesses += 1;
    this.setState({
      incorrectGuesses: newIncorrectGuesses
    })
  }

  async getGraphicDefinition(word) {
    // Check for definition wrapper
    let definitionWrapper = document.querySelector('.definition-wrapper--graphic');

    if (!definitionWrapper) return;
    if (definitionWrapper.innerHTML !== '') return;

    // Get definition from dictionary API
    let headersList = {
      "Accept": "*/*"
    }
    
    let response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`, { 
      method: "GET",
      headers: headersList
    });
    
    let data = await response.json();

    // Check response
    if (data.title) {
      // TODO: Something went wrong with the request
      definitionWrapper.innerHTML = "<p>Sorry, we couldn't find a definition at this time</p>";
      return;
    };

    // Output definition
    this.clearGraphicDefinition();

    let partOfSpeech = data[0]?.meanings[0]?.partOfSpeech;
    // let firstDefinition = data[0]?.meanings[0]?.definitions?.[0]?.definition;
    // firstDefinition = firstDefinition.replaceAll(this.state.word, '???');

    let html = '';
    html += `<p>${partOfSpeech}</p>`;
    // html += `<p>${firstDefinition}</p>`;

    definitionWrapper.innerHTML = html;
  }

  changeLanguage(lang) {
    this.setState({
      'language': lang
    })
  }

   // ================= //
  // LIFECYCLE METHODS //
 // ================= //

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {

    if (this.state.screen === 'daily') {
      this.setCookie();
    }

    if (this.state.screen === 'practice') {
      if (this.state.incorrectGuesses >= this.state.chances - 1) {
        // Disable definition btn
        let definitionBtn = document.querySelector('.btn--definition');
        if (definitionBtn) {
          definitionBtn.setAttribute('disabled', true);
        }
      }
    }

    // Check state for win/lose conditions
    if (!this.state.gameOver) {
      return;
    }

    if (this.state.incorrectGuesses >= this.state.chances) {
      this.updateGraphic(false);
    }

    if (this.state.win) {
      this.updateGraphic(true)
    }
  }

  render() {
    return (
      <div className="App">

        {
          this.state.screen === 'home' ?
          <Home 
            switchScreen={this.switchScreen}
            openModal={this.openModal}
            information={translations[this.state.language].information['info-btn']}
          /> : ''
        }
        {
          this.state.screen === 'daily' ?
            <Daily
              returnHome={this.returnHome}
              state={this.state}
              guessLetter={this.guessLetter}
              handleClick={this.handleClick}
              checkIncorrectGuess={this.checkIncorrectGuess}
              checkAllGuessed={this.checkAllGuessed}
              toggleDifficulty={this.toggleDifficulty}
              generateWord={this.generateWord}
              updateGraphic={this.updateGraphic}
              share={this.share}
              nativeShare={this.nativeShare}
              setCookie={this.setCookie}
              getCookie={this.getCookie}
              checkCookie={this.checkCookie}
              getGraphicDefinition={this.getGraphicDefinition}
            /> : ''
        }
        {
          this.state.screen === 'practice' ?
            <Practice
              returnHome={this.returnHome}
              state={this.state}
              guessLetter={this.guessLetter}
              handleClick={this.handleClick}
              checkIncorrectGuess={this.checkIncorrectGuess}
              checkAllGuessed={this.checkAllGuessed}
              toggleDifficulty={this.toggleDifficulty}
              generateWord={this.generateWord}
              updateGraphic={this.updateGraphic}
              resetBoard={this.resetBoard}
              getDefinition={this.getDefinition}
              getGraphicDefinition={this.getGraphicDefinition}
            /> : ''
        }

        <div className='language-wrapper'>
          <button title='English' className={this.state.language === 'en' ? 'language-toggle active language-toggle--en' : 'language-toggle language-toggle--en'} onClick={() => this.changeLanguage('en')} data-language="en"></button>
          <button title='Korean' className={this.state.language === 'kr' ? 'language-toggle active language-toggle--kr' : 'language-toggle language-toggle--kr'} onClick={() => this.changeLanguage('kr')} data-language="kr"></button>
          <button title='Japanese' className={this.state.language === 'jp' ? 'language-toggle active language-toggle--jp' : 'language-toggle language-toggle--jp'} onClick={() => this.changeLanguage('jp')} data-language="jp"></button>
        </div>

        <div className='Modal'>
          <button className='close-modal' onClick={() => this.closeModal()}>X</button>
          <div className='modal__inner'></div>
        </div>

      </div>
    );
  }
  
}

export default App;
